<template>
    <div v-if="isMobile"
         style="display: flex;flex-direction: column;justify-content: center;align-items: center;padding-top: 150px">
        <img style="width: 120px;height: 120px" src="../assets/123.jpg">
        <span style="margin-top: 50px;font-size: 15px;color: #555555">移动端请前往小程序</span>
    </div>
    <div v-else class="index_container">
        <div class="index_container_header">
            <div class="index_container_header_menu">
                <span :class="menuIconIndex === '/home' ? 'item_s' : 'item'" @click="toIndex">我的首页</span>
                <span :class="menuIconIndex === '/about' ? 'item_s' : 'item'" @click="toAbout">关于我们</span>
            </div>
            <div class="index_container_header_search">
                <input placeholder="请输入内容" style="border: none" v-model="searchStr" @keydown.enter="commitAction"/>
                <div class="btn"><i class="el-icon-search" @click="commitAction"></i></div>
            </div>
        </div>
        <div class="index_container_content">
            <router-view :key="$route.fullPath"/>
            <div class="container-footer">
                <div class="container-footer-content">
                    <span>Copyright ©{{ currentYear }} 程序员的进阶之路</span>
                    <a target="_blank"
                       href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010402002804"
                       style="margin-left: 15px; display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                        <img src="../assets/备案图标.png" style="float:left;margin-right: 10px"/>
                        <span>豫公网安备41010402002804号</span>
                    </a>
                    <a target="_blank" href="https://beian.miit.gov.cn"
                       style="margin-left: 15px;">豫ICP备2021035863号-1</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "Index",
    data() {
        return {
            searchStr: "",
            menuIndex: 0,
            currentYear: new Date().getFullYear(),
            isMobile: false
        }
    },
    computed: {
        ...mapState([
            "menuIconIndex"
        ])
    },
    created() {
        this.isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    },
    methods: {
        commitAction() {
            this.$router.push({
                path: "/list?searchKey=" + this.searchStr
            })
        },
        toProduct() {
            this.$store.dispatch('setMenuIconIndex', '/home')
            window.open("https://template.know.hn.cn", "__blank")
        },
        toCommon() {
            this.$store.dispatch('setMenuIconIndex', '/home')
            window.open("https://api.know.hn.cn", "__blank")
        },
        toIndex() {
            this.$store.dispatch('setMenuIconIndex', '/home')
            this.$router.push({
                path: "/"
            })
        },
        toAbout() {
            this.$store.dispatch('setMenuIconIndex', '/about')
            this.$router.push({
                path: "/about"
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../common/theme";

.index_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .index_container_header {
        z-index: $level_One;
        height: $header_nav_height;
        background-color: $header_nav_bgColor;
        padding-left: calc(100% / 2 - $content_width / 2 + 40px);
        padding-right: calc(100% / 2 - $content_width / 2 + 50px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        top: 0;
        width: 100%;
        box-shadow: 0 0 3px 1px rgb(0 0 0 / 10%);

        .index_container_header_search {
            width: 300px;
            height: 30px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 15px;
            border-bottom: 0.5px rgba(255, 255, 255, 0.3) solid;

            input {
                flex: 1;
                height: 80%;
                border: none;
                outline: none;
                background-color: transparent;
                color: $header_nav_font_color;
                font-size: $header_nav_font_size;
            }

            input::-webkit-input-placeholder {
                color: rgba(255, 255, 255, 0.3);
                font-size: calc($header_nav_font_size - 2px);
            }

            .btn {
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: rgba(255, 255, 255, 0.3);
                font-size: $header_nav_font_size;
            }
        }

        .index_container_header_menu {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: $header_nav_font_color;
            font-size: $header_nav_font_size;
            font-weight: 400;

            .item_api {
                width: 120px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }

            .item {
                width: 90px;
                height: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                margin-left: 10px;

                &:hover {
                    background-color: rgba(255, 255, 255, 0.1);
                    border-radius: 5px;
                    color: orange;
                }
            }

            .item:first-child {
                margin-left: 0;
            }

            .item_s {
                width: 90px;
                height: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                background-color: rgba(255, 255, 255, 0.1);
                border-radius: 5px;
                margin-left: 10px;
                color: orange;

                &:hover {
                    background-color: rgba(255, 255, 255, 0.1);
                    border-radius: 5px;
                    color: orange;
                }
            }

            .item_s:first-child {
                margin-left: 0;
            }
        }
    }

    .index_container_cover {
        z-index: $level_One;
        width: 100%;
        height: $space_width;
        background-color: $body_bgColor;
        position: fixed;
        top: $header_nav_height;
    }

    .index_container_content {
        z-index: $level_Two;
        width: 100%;
        padding-left: calc(100% / 2 - $content_width / 2);
        padding-right: calc($space_width * 3);
        min-height: 800px;

        .container-footer {
            z-index: $level_One;
            margin-top: $space_width;
            width: 100%;
            height: $content_footer_height;
            padding-left: calc(100% / 2 - $content_width / 2);
            padding-right: calc(100% / 2 - $content_width / 2);
            background-color: $content_footer_bg_color;
            display: flex;
            justify-content: center;
            align-items: center;

            .container-footer-content {
                color: $content_footer_font_color;
                font-size: $content_footer_font_size;

                a {
                    color: $content_footer_font_color;
                    font-size: $content_footer_font_size;
                    text-decoration: none;
                }
            }
        }
    }
}
</style>
