<template>
    <div class="detail_container">
        <div class="detail_container_left">
            <div class="detail_container_left_item" v-for="(item,index) in bokeList" :key="index"
                 @click="toDetail(item)">
                <span><span style="margin-right: 10px;">{{ index + 1 }}. </span>{{ item.title }}</span>
            </div>
        </div>
        <div class="detail_container_right" v-if="bokeInfo">
            <span class="title">{{ bokeInfo.title }}</span>
            <el-divider><span class="tool">发布时间: {{ bokeInfo.cTime }} <i style="margin-left: 15px;color: red"
                                                                             class="iconfont">&#xe61a;</i>: {{
                    bokeInfo.visit
                }}</span></el-divider>
            <div class="detail_container_right_content">
                <Editor
                    style="width: 100%"
                    v-model="bokeInfo.content"
                    :defaultConfig="editorConfig"
                    :mode="mode"
                    @onCreated="onCreated"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Network from "./Network";
import {Editor} from "@wangeditor/editor-for-vue";
import {mapState} from "vuex";

export default {
    name: "Detail",
    components: {Editor},
    data() {
        return {
            bokeInfo: null,
            bokeList: [],
            payVoucherDialog: false,
            imageList: [],
            editor: null,
            editorConfig: {
                readOnly: true
            },
            mode: 'default'
        }
    },
    created() {
        window.scroll(0, 0);
        if (this.$route.query.id) {
            this.showDelete = true;
            this.loadInfo()
        }
        window.showMessage = () => {
            this.$message({
                message: "复制成功!",
                type: "success",
                customClass: "messageAlert"
            })
        }
    },
    computed: {
        ...mapState([
            "screenWidth"
        ])
    },
    methods: {
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
            let video_box = document.getElementsByClassName("w-e-textarea-video-container")
            if (video_box && video_box.length > 0) {
                video_box[0].style.backgroundImage = "none"
                video_box[0].style.border = "none"
            }
            for (let tbodyIndex in document.getElementsByTagName("tbody")) {
                let tbody = document.getElementsByTagName("tbody")[tbodyIndex]
                if (typeof (tbody) === 'object') {
                    for (let trIndex in tbody.getElementsByTagName("tr")) {
                        let tr = tbody.getElementsByTagName("tr")[trIndex]
                        if (typeof (tr) === 'object') {
                            for (let thIndex in tr.getElementsByTagName("th")) {
                                let th = tr.getElementsByTagName("th")[thIndex]
                                if (typeof (th) === 'object') {
                                    th.style.borderRightWidth = '1px';
                                }
                            }
                        }
                    }
                }
            }
            for (let codeIndex in document.getElementsByTagName("code")) {
                let code = document.getElementsByTagName("code")[codeIndex]
                if (typeof (code) === 'object') {
                    code.style.padding = '20px 15px'
                    code.parentElement.style.position = 'relative';
                    code.parentElement.style.paddingTop = '10px';
                    let span = document.createElement('span')
                    span.style.fontSize = '13px'
                    span.style.color = '#555'
                    span.innerText = "复制"
                    span.style.position = 'absolute'
                    span.style.right = '12px'
                    span.style.top = '23px'
                    span.style.cursor = 'pointer'
                    span.onclick = function () {
                        let textarea = document.createElement("textarea"); // 创建input对象
                        textarea.value = code.innerText; // 设置复制内容
                        document.body.appendChild(textarea); // 添加临时实例
                        textarea.select(); // 选择实例内容
                        document.execCommand("Copy"); // 执行复制
                        document.body.removeChild(textarea); // 删除临时实例
                        span.innerText = "复制成功!"
                        setTimeout(() => {
                            span.innerText = "复制"
                        }, 1000)
                        window.showMessage()
                    }
                    code.parentElement.appendChild(span)
                }
            }
        },
        toDetail(item) {
            this.$router.push({
                path: "/detail?id=" + item.id
            })
        },
        getBokeTitleByCategory() {
            Network.getBokeTitleByCategory({
                category: this.bokeInfo.category
            }).then(res => {
                this.bokeList = res;
            })
        },
        loadInfo() {
            Network.getBokeById(this.$route.query.id).then(res => {
                this.bokeInfo = res;
                this.getBokeTitleByCategory();
            })
        }
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
}
</script>

<style lang="scss" scoped>
@import "../common/theme";

.detail_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .detail_container_left {
        padding: 15px 20px 50px 20px;
        position: sticky;
        top: $header_nav_height;
        width: $content_menu_width;
        min-height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .detail_container_left_item {
            font-family: -apple-system, SF UI Text, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei, WenQuanYi Micro Hei, sans-serif;
            width: 100%;
            padding: 5px 10px;
            text-align: left;
            cursor: pointer;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            color: $content_left_menu_font_color;
            font-size: $content_left_menu_font_size;
            font-family: $body_font-family;

            &:hover {
                color: #ff6000;
                text-decoration: underline;
            }
        }
    }

    .detail_container_right {
        border-left: 1px #eeeeee solid;
        width: calc(100% - $space_width - $content_menu_width);
        margin-top: $header_nav_height;
        margin-left: 20px;
        border-radius: 5px;
        min-height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: white;
        padding: $space_width;

        .title {
            font-weight: bold;
            color: #555;
            width: 100%;
            text-align: center;
            font-size: 25px;
            margin-bottom: calc($space_width * 2);
        }

        .tool {
            color: #8a8a8a;
        }

        .detail_container_right_content {
            width: 100%;
            background-color: transparent;
            font-size: 15px;
            font-weight: 400;
            color: #555;
            margin-top: $space_width;
            text-align: left;
        }
    }

}
</style>
