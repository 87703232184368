<template>
    <div class="about_container">
        联系: 384894903
    </div>
</template>

<script>
export default {
    name: "About"
}
</script>

<style lang="scss" scoped>
@import "../common/theme";

.about_container {
    width: 100%;
    min-height: 900px;
    margin-top: $space_width + $header_nav_height;
    padding-top: 100px;
}
</style>
