<template>
    <div class="list_container">
        <div class="list_container_left" v-if="!showCategory">
            <div class="list_container_left_item_book" v-for="(item,index) in categoryList" :key="index"
                 @click="toList(item.category)">
                <span style="margin-left: 15px">{{ item.category }}({{ item.num }})</span>
            </div>
        </div>
        <div class="list_container_left" v-else>
            <div class="list_container_left_item_article" v-for="(item,index) in articleList" :key="index"
                 @click="toDetail(item)">
                <span><span style="margin-right: 10px;">{{ index + 1 }}. </span>{{
                        item.title
                    }}</span>
            </div>
        </div>
        <div class="list_container_right">
            <div class="list_container_right_sort_tool">
                <span :class="sortIndex === 0 ? 'sort_tool_item_s':'sort_tool_item'"
                      @click="sortAction('', 0)">默认</span>
                <span :class="sortIndex === 1 ? 'sort_tool_item_s':'sort_tool_item'"
                      @click="sortAction('cTime', 1)">发布时间</span>
                <span :class="sortIndex === 2 ? 'sort_tool_item_s':'sort_tool_item'"
                      @click="sortAction('visit', 2)">访问量</span>
            </div>
            <div class="list_container_right_content">
                <div class="content_bg" v-for="(item,index) in dataList" :key="index" @click="toDetail(item)">
                    <div class="item">
                        <div class="item_left">
                            <el-image v-if="item.imageList===null" class="item_left_image">
                                <div slot="error">{{ item.category }}</div>
                            </el-image>
                            <el-image v-else class="item_left_image" :src="item.imageList[0]">
                                <div slot="error">{{ item.category }}</div>
                            </el-image>
                        </div>
                        <div class="item_right">
                            <div class="item_right_top">
                                <span class="title">{{ item.title }}</span>
                                <span class="cDes" v-html="item.content"></span>
                            </div>
                            <span class="des">
                                      <i class="el-icon-collection-tag"></i>: {{ item.category }}
                                      <span style="margin-left: 15px"><i class="el-icon-time"></i>: {{
                                              item.cTime
                                          }}</span>
                                      <span style="margin-left: 15px">
                                          <i class="iconfont" style="color: red">&#xe61a;</i>: {{ item.visit }}
                                      </span>
                                    </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="list_container_right_pagination">
                <el-pagination
                    background
                    @current-change="currentChange"
                    layout="prev, pager, next"
                    :page-size="pageSize"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import Network from "./Network";

export default {
    name: "List",
    data() {
        return {
            sortIndex: 0,
            pageSize: 10,
            pageNum: 0,
            total: 0,
            searchKey: "",
            sortBy: "cTime",
            sortKey: "DESC",
            category: "",
            dataList: [],
            categoryList: [],
            articleList: [],
            showCategory: false
        }
    },
    created() {
        window.scroll(0, 0);
        if (this.$route.query.searchKey) {
            this.searchKey = this.$route.query.searchKey;
        }
        if (this.$route.query.category) {
            this.showCategory = true
            this.category = this.$route.query.category;
            this.getBokeTitleByCategory()
        } else {
            this.showCategory = false
            this.loadGroupCategory()
        }
        this.loadList()
    },
    methods: {
        sortAction(sort, index) {
            this.sortIndex = index
            if (sort === '') {
                this.sortBy = "cTime"
                this.sortKey = "DESC"
            } else {
                this.sortBy = sort
                this.sortKey = this.sortKey === "DESC" ? "ASC" : "DESC"
            }
            this.loadList()
        },
        toList(category) {
            this.$router.push({
                path: "/list?category=" + category
            })
        },
        getBokeTitleByCategory() {
            Network.getBokeTitleByCategory({
                category: this.category
            }).then(res => {
                this.articleList = res;
            })
        },
        loadGroupCategory() {
            Network.getGroupNumByCategory().then(res => {
                this.categoryList = res;
            })
        },
        loadList: function () {
            Network.list({
                pageSize: this.pageSize,
                pageNum: this.pageNum,
                title: this.searchKey,
                category: this.category,
                sortBy: this.sortBy,
                sortKey: this.sortKey
            }).then(res => {
                this.total = res.count
                this.dataList = res.list.map(item => {
                    let reg = /(\/|http)[^>]+\.(jpg|jpeg|png|gif)/g;
                    let imageList = item.content.match(reg)
                    item.imageList = imageList
                    item.content = item.content.replace(/<\/?.+?\/?>/g, '')
                    return item
                })
            })
        },
        currentChange: function (index) {
            this.pageNum = index - 1;
            this.loadList();
        },
        toDetail(item) {
            this.$router.push({
                path: "/detail?id=" + item.id
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../common/theme";

.list_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .list_container_left {
        background-color: white;
        padding: 15px 20px 50px 20px;
        position: sticky;
        top: $header_nav_height;
        width: $content_menu_width;
        min-height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .list_container_left_item_book {
            font-family: -apple-system, SF UI Text, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei, WenQuanYi Micro Hei, sans-serif;
            width: 100%;
            text-align: left;
            cursor: pointer;
            padding: 5px 10px;
            margin-top: 5px;
            border-radius: 8px;
            color: $content_left_menu_font_color;
            font-size: $content_left_menu_font_size;
            font-family: $body_font-family;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;

            &:hover {
                background-color: $content_left_menu_font_hover_bgColor;
            }
        }

        .list_container_left_item_article {
            width: 100%;
            text-align: left;
            cursor: pointer;
            color: $content_left_menu_font_color;
            font-size: $content_left_menu_font_size;
            font-family: $body_font-family;
            padding: 5px 10px;
            margin-top: 5px;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;

            &:hover {
                color: #ff6000;
                text-decoration: underline;
            }
        }
    }

    .list_container_right {
        border-left: 1px #eeeeee solid;
        width: calc(100% - $space_width - $content_menu_width);
        margin-top: calc($header_nav_height + 50px);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .list_container_right_sort_tool {
            z-index: $level_Two;
            background-color: #f9f9ff;
            position: fixed;
            top: $header_nav_height;
            left: calc(100% / 2 - $content_width / 2 + $content_menu_width + $space_width);
            width: calc($content_width - $content_menu_width);
            padding-left: $space_width;
            height: 50px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-left: 1px #eeeeee solid;

            .sort_tool_item_s {
                font-size: 15px;
                color: cornflowerblue;
                margin-left: 15px;
                cursor: pointer;
            }

            .sort_tool_item {
                font-size: 15px;
                color: #666666;
                margin-left: 15px;
                cursor: pointer;
            }
        }

        .list_container_right_content {
            width: 100%;
            min-height: 800px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            background-color: white;
            padding: 10px;

            .content_bg {
                width: 100%;
                height: $content_article_item_height;
                padding-left: $space_width;
                padding-right: $space_width;

                //&:hover {
                //    background-color: #f9f9ff;
                //}

                .item {
                    font-family: $body_font-family;
                    padding: $space_width 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    border-bottom: 0.5px #f4f4f4 solid;
                    cursor: pointer;

                    .item_left {
                        width: 250px;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;

                        .item_left_image {
                            width: 100%;
                            height: 100%;
                            border: 0.5px rgba(244, 247, 250, 1) solid;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            font-weight: 800;
                            background-color: rgba(244, 247, 250, 0.5);
                            color: #207AA8;
                            font-style: oblique;
                        }
                    }

                    .item_right {
                        height: 100%;
                        margin-left: 15px;
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-start;

                        .item_right_top {
                            width: 100%;
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;

                            .title {
                                width: 100%;
                                font-size: 22px;
                                font-weight: bold;
                                color: #333;
                                text-align: left;
                                word-wrap: break-word;

                                &:hover {
                                    color: orange;
                                }
                            }

                            .cDes {
                                margin-top: 10px;
                                font-size: 13px;
                                line-height: 24px;
                                color: #999;
                                text-align: left;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 4;
                                -webkit-box-orient: vertical;

                                &:hover {
                                    color: orange;
                                }
                            }
                        }

                        .des {
                            margin-top: 10px;
                            font-size: 12px;
                            font-weight: 400;
                            color: #b4b4b4;
                            text-align: left;

                            &:hover {
                                color: orange;
                            }
                        }
                    }
                }
            }

        }


        .list_container_right_pagination {
            margin-top: $space_width;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

}
</style>
