export default class Network {
    static list(data) {
        return new Promise(((resolve, reject) => {
            window.go({
                url: "/boke/userBoke/list",
                data: data
            }).then(res => {
                resolve(res.result)
            }, error => {
                reject(error)
            })
        }))
    }

    static getGroupNumByCategory() {
        return new Promise(((resolve, reject) => {
            window.go({
                url: "/boke/userBoke/getGroupNumByCategory",
            }).then(res => {
                resolve(res.result)
            }, error => {
                reject(error)
            })
        }))
    }

    static getBokeTitleByCategory(data) {
        return new Promise(((resolve, reject) => {
            window.go({
                url: "/boke/userBoke/getBokeGetByCategory",
                data: data
            }).then(res => {
                resolve(res.result)
            }, error => {
                reject(error)
            })
        }))
    }


    static getBokeById(id) {
        return new Promise(((resolve, reject) => {
            window.go({
                url: "/boke/userBoke/getBokeById",
                data: {
                    id: id
                }
            }).then(res => {
                resolve(res.result)
            }, error => {
                reject(error)
            })
        }))
    }
}
